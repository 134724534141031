<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="products">
      <div class="wrapper">
        <trac-back-button> Back </trac-back-button>
        <div class="flex flex-row justify-between items-end">
          <ul class="flex-col mt-12 mb-8">
            <li><a href="#">Inventory Control</a></li>
            <li class="font-bold mt-4 text-2xl">Remove Stock</li>
          </ul>
          <div class="mb-8">
            <trac-button
              @button-clicked="gotoAdjustmentScreen"
              class="uppercase"
              :disabled="selectedProducts.length === 0"
              >Confirm</trac-button
            >
          </div>
        </div>
        <div class="p-4 bg-white h-full rounded-lg big-shadow">
          <div class="mt-8 mb-5 mx-8 justify-between flex flex-row">
            <div class="flex flex-row items-center">
              <trac-dropdown
                @input="selectCategory"
                :title="categories[0].name"
                class="mr-5"
                :menuItems="categories"
              ></trac-dropdown>
            </div>
          </div>
          <div class="m-4">
            <p class="text-xs text-gray-600">
              <span class="text-red-600">*</span> Only products that are stock
              managed are displayed.
            </p>
          </div>
          <div class="mx-8 border rounded-lg h-128 overflow-auto">
            <div class="grid grid-table bg-blue-100">
              <p class="text-left text-xs font-semibold p-4 uppercase">
                <trac-checkbox></trac-checkbox>
              </p>
              <p class="text-left text-xs font-semibold p-4 uppercase">Item</p>
              <p class="text-left text-xs font-semibold p-4 uppercase">SKU</p>
              <p class="text-left text-xs font-semibold p-4 uppercase">
                Category
              </p>
              <p class="text-left text-xs font-semibold p-4 uppercase">
                Qty in stock
              </p>
              <p class="text-left text-xs font-semibold p-4 uppercase">Price</p>
            </div>
            <div class="" v-if="filteredProducts.length > 0">
              <div
                class=""
                v-for="(item, index) in filteredProducts"
                :key="index"
              >
              <!-- @click="selectProduct(item)" -->
                <div
                  class="hover:bg-gray-200 cursor-pointer grid grid-table"
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                >
                  <p class="text-xs p-4">
                    <trac-checkbox
                    v-if="!item.variation"
                      @check="selectProduct(item)"
                      :isChecked="selectedIDs.includes(item._id)"
                    ></trac-checkbox>
                  </p>
                  <p class="text-xs p-4 capitalize">
                    {{ item.title }}
                  </p>
                  <p class="text-xs p-4">
                    {{ item.barcode }}
                  </p>
                  <p class="text-xs p-4 capitalize">
                    {{ item.category }}
                  </p>
                  <p class="text-xs p-4">
                    {{ item.variation ? 'N/A' : (selectedStoreStock(item) || {}).stock }}
                  </p>
                  <p class="text-xs p-4">
                    {{ item.price | formatPrice }}
                  </p>
                </div>
                <div class="" v-if="item.variation">
                  <div
                    class="hover:bg-gray-200 cursor-pointer grid grid-table"
                    :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                    v-for="(variant, index) in item.varieties"
                    :key="index"
                  >
                    <!-- @click="
                      selectProduct({ ...variant, is_local_variant: true })
                    " -->
                    <p class="text-xs p-4">
                      <trac-checkbox
                        :ref="'varCheckbox' + index"
                        @check="
                          selectProduct({ ...variant, is_local_variant: true, parent_product: item })
                        "
                        :isChecked="selectedIDs.includes(variant._id)"
                      ></trac-checkbox>
                    </p>
                    <p class="text-xs p-4 capitalize">
                      {{ variant.title }}
                    </p>
                    <p class="text-xs p-4">
                      {{ variant.barcode }}
                    </p>
                    <p class="text-xs p-4 capitalize">
                      {{ item.category }}
                    </p>
                    <p class="text-xs p-4">
                      {{ variant.stock }}
                    </p>
                    <p class="text-xs p-4">
                      {{ variant.price | formatPrice }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="" v-else>
              <trac-center-data>
                <div
                  class="h-96 flex justify-center items-center text-lg text-gray-600"
                >
                  No match found.
                </div>
              </trac-center-data>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA, SAVE_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  name: "AddStock",
  data() {
    return {
      isLoading: false,
      categories: null,
      selectedStore: null,
      products: null,
      selectedIDs: [],
      selectedProducts: [],
      filteredProducts: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.selectedStore = GET_LOCAL_DB_DATA("stock-adjustment-store-detail");
    await this.fetchAllCategories();
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  beforeRouteEnter(to, from, next) {
    if (!GET_LOCAL_DB_DATA("stock-adjustment-store-detail")) {
      next({ name: "AddStockNext" });
    } else {
      next();
    }
  },
  methods: {
    gotoAdjustmentScreen() {
      SAVE_LOCAL_DB_DATA("products-to-adjust", this.selectedProducts);
      this.$router.push({ name: "RemoveStockNext" });
    },
    selectProduct(product) {
      if (this.selectedIDs.includes(product._id)) {
        this.selectedProducts = this.selectedProducts.filter(
          (prod) => product._id !== prod._id
        );
        this.selectedIDs = this.selectedIDs.filter((id) => product._id !== id);
      } else {
        this.selectedProducts.push(product);
        this.selectedIDs.push(product._id);
      }
    },
    selectCategory(value) {
      if (value === "Select Category") {
        this.filteredProducts = this.products;
      } else {
        this.filteredProducts = this.products.filter(
          (product) => product.category.toLowerCase() === value.toLowerCase()
        );
      }
    },
    selectedStoreStock(product) {
      return product.store_stock.find(
        (ss) => ss.store_id === this.selectedStore._id
      );
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 1) {
          this.categories = data.slice(1, data.length);
          this.categories.unshift({ name: "Select Category", count: 0 });
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
        this.filteredProducts = this.products.filter(
          (prod) => prod.manage_stock
        );
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
  },
};
</script>

<style scoped>
.grid-table {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
}
</style>
